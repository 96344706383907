



































import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import {
  PreferenceAccountState,
  StorageStatePreference,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import PreferenceAccountBalanceSheet from "./PreferenceAccountBalanceSheet.vue";
import PreferenceAccountSalesPurchase from "./PreferenceAccountSalesPurchase.vue";
import PreferenceAccountArAp from "./PreferenceAccountArAp.vue";
import PreferenceAccountInventory from "./PreferenceAccountInventory.vue";
import PreferenceAccountTax from "./PreferenceAccountTax.vue";

export default Vue.extend({
  name: "PreferenceAccount",
  components: {
    PreferenceAccountBalanceSheet,
    PreferenceAccountSalesPurchase,
    PreferenceAccountArAp,
    PreferenceAccountInventory,
    PreferenceAccountTax,
  },
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.account as PreferenceAccountState,
      store: (st: any) => st.preferenceStore as StorageStatePreference,
    }),
  },
  provide() {
    return {
      ["PreferenceAccount"]: () => ({
        options: this.coaOptions,
      }),
    };
  },
  data() {
    return {
      coaOptions: [] as Option<AccountingAccountResponseDto>[],
    };
  },
  mounted() {
    this.initPreferenceFormAccount();
    this.fetchCoaList();
  },
  methods: {
    ...mapActions({
      initPreferenceFormAccount: "preferenceStore/initPreferenceFormAccount",
      updatePreferenceAccount: "preferenceStore/updatePreferenceAccount",
    }),
    async fetchCoaList() {
      const { findAllChildAccount, toOptions } = useCoa();
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      const response = await findAllChildAccount(params);
      this.coaOptions = toOptions(response.data);
    },
    onSave() {
      this.updatePreferenceAccount();
    },
  },
});
