
































import { buildPreferenceFieldFormStates } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { PreferenceFieldFormState } from "@/models/interface/preference";
import {
  EnumPreferenceAccountInventoryKey,
  PreferenceAccountInventoryState,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapState } from "vuex";

const names: EnumPreferenceAccountInventoryKey[] = [
  "inventory",
  "nonStock",
  "waste",
  "stockAdjustment",
  "stockVariance",
  "inventoryInTransit",
];
const testids: string[] = [
  "account-inventory-inventory",
  "account-inventory-non-stock",
  "account-inventory-waste",
  "account-inventory-stock-adjustment",
  "account-inventory-stock-variance",
  "account-inventory-in-transit",
];
const keys: EnumPreferenceKey[] = [
  "account_setup_inventory",
  "account_setup_inventory_non_stock",
  "account_setup_inventory_waste",
  "account_setup_stock_adjustment",
  "account_setup_stock_variance",
  "account_inventory_in_transit",
];
const labels: string[] = [
  "lbl_inventory",
  "lbl_inventory_non_stock",
  "lbl_inventory_waste",
  "lbl_stock_adjustment",
  "lbl_stock_variance",
  "lbl_inventory_in_transit",
];

export default Vue.extend({
  name: "PreferenceAccounInventory",
  inject: ["PreferenceAccount"],
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.account
          .inventory as PreferenceAccountInventoryState,
    }),
    coaOptions(): Option<AccountingAccountResponseDto>[] {
      return this.isSearching ? this.options : this.PreferenceAccount().options;
    },
  },
  data() {
    this.onSearchCoa = debounceProcess(this.onSearchCoa, 500);
    return {
      accountState: buildPreferenceFieldFormStates({
        keys,
        labels,
        names,
        testids,
      }) as PreferenceFieldFormState<EnumPreferenceAccountInventoryKey>[],
      options: [] as Option<AccountingAccountResponseDto>[],
    };
  },
  methods: {
    async onSearchCoa(
      field: PreferenceFieldFormState<EnumPreferenceAccountInventoryKey>,
      value?: string
    ) {
      const { findAllChildAccount, filterBy, toOptions } = useCoa();
      field.isSearching = true;
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      params.search = filterBy({ code: value, description: value });
      try {
        field.loading = true;
        const response = await findAllChildAccount(params);
        field.options = toOptions(response.data);
      } finally {
        field.loading = false;
      }
    },
  },
});
