










































































import { useFindMasterTop } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { StorageStatePreference } from "@/store/models/Preference.model";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "PreferencePurchase",
  computed: {
    ...mapState({
      state: (st: any) => st.preferenceStore as StorageStatePreference,
    }),
  },
  data() {
    return {
      booleanOptions: [
        {
          label: this.$t("lbl_yes"),
          value: "true",
        },
        {
          label: this.$t("lbl_no"),
          value: "false",
        },
      ],
      topOptions: [] as Option[],
      loading: {
        invoiceTerms: false,
      },
    };
  },
  mounted() {
    this.fetchTopList();
    this.initPreferenceFormPurchase();
  },
  methods: {
    ...mapActions({
      initPreferenceFormPurchase: "preferenceStore/initPreferenceFormPurchase",
      updatePreferencePurchase: "preferenceStore/updatePreferencePurchase",
    }),
    async fetchTopList() {
      try {
        this.loading.invoiceTerms = true;
        const res = await useFindMasterTop();
        this.topOptions = res.map(item => ({
          key: item.id,
          label: item.value,
          value: item.id,
        }));
      } finally {
        this.loading.invoiceTerms = false;
      }
    },
    onSave() {
      this.updatePreferencePurchase();
    },
  },
});
