var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_basic_feature")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [_vm._v(" " + _vm._s(_vm.$t("lbl_tax")) + " ")])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.vatOutTaxOptions,
                            "dropdown-match-select-width": false,
                            "show-search": "",
                            "label-in-value": "",
                            loading: _vm.loading.tax,
                            "filter-option": false,
                            "data-testid": "truck-tax"
                          },
                          on: { search: _vm.onSearchTaxVatOut },
                          model: {
                            value: _vm.state.tax.value,
                            callback: function($$v) {
                              _vm.$set(_vm.state.tax, "value", $$v)
                            },
                            expression: "state.tax.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_assignee")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.employeeOptions,
                            "show-search": "",
                            "label-in-value": "",
                            "data-testid": "truck-assignee",
                            "filter-option": false,
                            "dropdown-match-select-width": false,
                            loading: _vm.loading.assignee
                          },
                          on: { search: _vm.onSearchEmployee },
                          model: {
                            value: _vm.state.assignee.value,
                            callback: function($$v) {
                              _vm.$set(_vm.state.assignee, "value", $$v)
                            },
                            expression: "state.assignee.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_account")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_down_payment")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.accountDownPaymentOptions,
                            "label-in-value": "",
                            "show-search": "",
                            "filter-option": false,
                            "dropdown-match-select-width": false,
                            loading: _vm.loading.accountDownPaymentOptions,
                            "data-testid": "truck-account-down-payment"
                          },
                          on: {
                            search: function($event) {
                              return _vm.onSearchAccount(
                                "accountDownPaymentOptions",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.state.downPayment.value,
                            callback: function($$v) {
                              _vm.$set(_vm.state.downPayment, "value", $$v)
                            },
                            expression: "state.downPayment.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_revenue_account")) + " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.accountRevenueOptions,
                            "label-in-value": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.accountRevenueOptions,
                            "dropdown-match-select-width": false,
                            "data-testid": "truck-account-revenue"
                          },
                          on: {
                            search: function($event) {
                              return _vm.onSearchAccount(
                                "accountRevenueOptions",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.state.revenue.value,
                            callback: function($$v) {
                              _vm.$set(_vm.state.revenue, "value", $$v)
                            },
                            expression: "state.revenue.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_receivable_account")) + " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.accountReceivableOptions,
                            "label-in-value": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.accountReceivableOptions,
                            "dropdown-match-select-width": false,
                            "data-testid": "truck-account-receivable"
                          },
                          on: {
                            search: function($event) {
                              return _vm.onSearchAccount(
                                "accountReceivableOptions",
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.state.receivableAccount.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.receivableAccount,
                                "value",
                                $$v
                              )
                            },
                            expression: "state.receivableAccount.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_branch")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_branch")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.branchOptions,
                            "show-search": "",
                            "label-in-value": "",
                            "data-testid": "truck-default-branch",
                            "filter-option": false,
                            "dropdown-match-select-width": false,
                            loading: _vm.loading.branchOptions
                          },
                          on: { search: _vm.fetchBranchList },
                          model: {
                            value: _vm.state.defaultBranch.value,
                            callback: function($$v) {
                              _vm.$set(_vm.state.defaultBranch, "value", $$v)
                            },
                            expression: "state.defaultBranch.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.$can("update", "preferences")
        ? _c(
            "a-row",
            { staticClass: "mt-3", attrs: { gutter: [16, 48], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "truck-save",
                        loading: _vm.store.loadingSave
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }