
































import { buildPreferenceFieldFormStates } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { PreferenceFieldFormState } from "@/models/interface/preference";
import {
  EnumPreferenceAccountArApKey,
  PreferenceAccountArApState,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapState } from "vuex";

const names: EnumPreferenceAccountArApKey[] = [
  "accountReceivable",
  "accountPayable",
  "invoicePriceVariance",
  "prepaymentAr",
  "prepaymentAp",
];

const testids: string[] = [
  "account-arap-account-receivable",
  "account-arap-account-payable",
  "account-arap-invoice-price-variance",
  "account-arap-prepayment-ar",
  "account-arap-prepayment-ap",
];
const keys: EnumPreferenceKey[] = [
  "account_setup_account_receiviables",
  "account_setup_account_payables",
  "account_setup_invoice_price_variance",
  "account_setup_prepayment_ar",
  "account_setup_prepayment_ap",
];
const labels: string[] = [
  "lbl_account_receivable",
  "lbl_account_payable",
  "lbl_invoice_price_variance",
  "lbl_prepayment_ar",
  "lbl_prepayment_ap",
];

export default Vue.extend({
  name: "PreferenceAccountArAp",
  inject: ["PreferenceAccount"],
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.account.arAp as PreferenceAccountArApState,
    }),
    coaOptions(): Option<AccountingAccountResponseDto>[] {
      return this.isSearching ? this.options : this.PreferenceAccount().options;
    },
  },
  data() {
    this.onSearchCoa = debounceProcess(this.onSearchCoa, 500);
    return {
      accountState: buildPreferenceFieldFormStates({
        keys,
        labels,
        names,
        testids,
      }) as PreferenceFieldFormState<EnumPreferenceAccountArApKey>[],
      options: [] as Option<AccountingAccountResponseDto>[],
    };
  },
  methods: {
    async onSearchCoa(
      field: PreferenceFieldFormState<EnumPreferenceAccountArApKey>,
      value?: string
    ) {
      const { findAllChildAccount, filterBy, toOptions } = useCoa();
      field.isSearching = true;
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      params.search = filterBy({ code: value, description: value });
      try {
        field.loading = true;
        const response = await findAllChildAccount(params);
        field.options = toOptions(response.data);
      } finally {
        field.loading = false;
      }
    },
  },
});
