var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 48], type: "flex" } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_basic_feature")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_basic_invoice_term")) + " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.topOptions,
                            "label-in-value": "",
                            loading: _vm.loading.invoiceTerms,
                            "data-testid": "purchase-invoice-terms"
                          },
                          model: {
                            value: _vm.state.field.purchase.invoiceTerms.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.purchase.invoiceTerms,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.purchase.invoiceTerms.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_quotation_and_purchase_order")) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "purchase-quotation-purchase-order"
                          },
                          model: {
                            value:
                              _vm.state.field.purchase.purchaseAndQuotation
                                .value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.purchase.purchaseAndQuotation,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.purchase.purchaseAndQuotation.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_purchase_order_return")) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "purchase-purchase-order-return"
                          },
                          model: {
                            value:
                              _vm.state.field.purchase.purchaseOrderReturn
                                .value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.purchase.purchaseOrderReturn,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.purchase.purchaseOrderReturn.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mt-3", attrs: { gutter: [16, 48], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _vm.$can("update", "preferences")
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.state.loadingSave,
                        "data-testid": "purchase-save"
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }