var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_preferences") } },
    [
      _c(
        "a-tabs",
        {
          on: { change: _vm.onChangeTab },
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        [
          _c(
            "a-tab-pane",
            { key: "feature", attrs: { tab: _vm.$t("lbl_feature") } },
            [_c("PreferenceFeature")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "sales", attrs: { tab: _vm.$t("lbl_sales") } },
            [_c("PreferenceSales")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "purchase", attrs: { tab: _vm.$t("lbl_purchase") } },
            [_c("PreferencePurchase")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "inventory", attrs: { tab: _vm.$t("lbl_inventory") } },
            [_c("PreferenceInventory")],
            1
          ),
          _c(
            "a-tab-pane",
            {
              key: "account-setup",
              attrs: { tab: _vm.$t("lbl_account_setup") }
            },
            [_c("PreferenceAccount")],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "truck", attrs: { tab: _vm.$t("lbl_truck") } },
            [_c("PreferenceTruck")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }