var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_basic_feature")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_multi_branch")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "feature-multi-branch"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.basicFeature.multiBranch
                                .value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.basicFeature
                                  .multiBranch,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.basicFeature.multiBranch.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_multi_currency")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "feature-multi-currency"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.basicFeature.multiCurrency
                                .value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.basicFeature
                                  .multiCurrency,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.basicFeature.multiCurrency.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_inclusive_tax")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "feature-multi-inclusive-tax"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.basicFeature.taxInclusive
                                .value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.basicFeature
                                  .taxInclusive,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.basicFeature.taxInclusive.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_approval")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "feature-approval"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.basicFeature.approval
                                .value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.basicFeature.approval,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.basicFeature.approval.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_asset_management")) + " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.booleanOptions,
                            "data-testid": "feature-asset-management"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.basicFeature
                                .assetManagement.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.basicFeature
                                  .assetManagement,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.basicFeature.assetManagement.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_currency_format")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_base_currency")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.currencyOptions,
                            "label-in-value": "",
                            "show-search": "",
                            loading: _vm.loading.currency,
                            "filter-option": false,
                            "data-testid": "feature-base-currency"
                          },
                          on: { search: _vm.onSearchCurrency },
                          model: {
                            value:
                              _vm.state.field.feature.currencyFormat
                                .baseCurrency.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.currencyFormat
                                  .baseCurrency,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.currencyFormat.baseCurrency.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_decimal_format")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.decimalFormatOptions,
                            loading: _vm.loading.decimalFormat,
                            "label-in-value": "",
                            "data-testid": "feature-decimal-format"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.currencyFormat
                                .decimalFormat.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.currencyFormat
                                  .decimalFormat,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.currencyFormat.decimalFormat.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_decimal_point")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.decimalPointOptions,
                            loading: _vm.loading.decimalPoint,
                            "data-testid": "feature-decimal-point"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.currencyFormat
                                .decimalPoint.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.currencyFormat
                                  .decimalPoint,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.currencyFormat.decimalPoint.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_discount_before_after_tax")) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.discountOptions,
                            loading: _vm.loading.discount,
                            "label-in-value": "",
                            "data-testid": "feature-discount-tax"
                          },
                          model: {
                            value:
                              _vm.state.field.feature.currencyFormat
                                .discountCalculation.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.currencyFormat
                                  .discountCalculation,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "\n                state.field.feature.currencyFormat.discountCalculation.value\n              "
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_tax_rate")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_sales_tax_rate")) + " ")
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.salesTaxOptions,
                            "label-in-value": "",
                            "show-search": "",
                            "data-testid": "feature-sales-tax",
                            "filter-option": false,
                            loading: _vm.loading.salesTax
                          },
                          on: { search: _vm.onSearchSalesTax },
                          model: {
                            value:
                              _vm.state.field.feature.taxRate.salesTax.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.taxRate.salesTax,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.taxRate.salesTax.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_purchase_tax_rate")) + " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.purchaseTaxOptions,
                            "data-testid": "feature-purchase-tax",
                            "label-in-value": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.purchaseTax
                          },
                          on: { search: _vm.onSearchPurchaseTax },
                          model: {
                            value:
                              _vm.state.field.feature.taxRate.purchaseTax.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.taxRate.purchaseTax,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "state.field.feature.taxRate.purchaseTax.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_account_receivable")) + " ")
                ]),
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16], type: "flex" } },
                  [
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_invoice_assignee")) + " "
                        )
                      ])
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c("a-select", {
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.employeeOptions,
                            "data-testid": "feature-assignee",
                            "label-in-value": "",
                            "show-search": "",
                            "dropdown-match-select-width": false,
                            "filter-option": false,
                            loading: _vm.loading.employee
                          },
                          on: { search: _vm.onSearchEmployee },
                          model: {
                            value:
                              _vm.state.field.feature.accountReceivable
                                .invoiceAssignee.value,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.state.field.feature.accountReceivable
                                  .invoiceAssignee,
                                "value",
                                $$v
                              )
                            },
                            expression:
                              "\n                state.field.feature.accountReceivable.invoiceAssignee.value\n              "
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mt-3", attrs: { gutter: [16, 48], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _vm.$can("update", "preferences")
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        loading: _vm.state.loadingSave,
                        "data-testid": "feature-save",
                        type: "primary"
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }