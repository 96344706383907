
































import { buildLabelInValue } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { PreferenceFieldFormState } from "@/models/interface/preference";
import {
  EnumPreferenceAccountBalanceSheetKey,
  PreferenceAccountBalanceSheetState,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "PreferenceAccountBalanceSheet",
  inject: ["PreferenceAccount"],
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.account
          .balanceSheet as PreferenceAccountBalanceSheetState,
    }),
    coaOptions(): Option<AccountingAccountResponseDto>[] {
      return this.isSearching ? this.options : this.PreferenceAccount().options;
    },
  },
  data() {
    this.onSearchCoa = debounceProcess(this.onSearchCoa, 500);
    return {
      accountState: [
        {
          name: "openingBalance",
          isSearching: false,
          key: "account_setup_opening_balance_equity",
          label: "lbl_opening_balance_equity",
          options: [],
          loading: false,
          testid: "account-balance-sheet-opening-balance",
          value: buildLabelInValue(),
        },
        {
          name: "retainedEarning",
          isSearching: false,
          key: "account_setup_retained_earning",
          label: "lbl_retained_earning",
          options: [],
          loading: false,
          testid: "account-balance-sheet-retained-earning",
          value: buildLabelInValue(),
        },
        {
          name: "currentYearProfit",
          isSearching: false,
          key: "account_setup_current_year_profit",
          label: "lbl_current_year_profit",
          options: [],
          loading: false,
          testid: "account-balance-sheet-year-profit",
          value: buildLabelInValue(),
        },
        {
          name: "currentMonthProfit",
          isSearching: false,
          key: "account_setup_current_month_profit",
          label: "lbl_current_month_profit",
          options: [],
          loading: false,
          testid: "account-balance-sheet-month-profit",
          value: buildLabelInValue(),
        },
        {
          name: "exchangeRateVarianceGain",
          isSearching: false,
          key: "account_setup_exchange_rate_variance_gain",
          label: "lbl_exchange_rate_variance_gain",
          options: [],
          loading: false,
          testid: "account-balance-sheet-rate-variance-gain",
          value: buildLabelInValue(),
        },
        {
          name: "exchangeRateVarianceLoss",
          isSearching: false,
          key: "account_setup_exchange_rate_variance_loss",
          label: "lbl_exchange_rate_variance_loss",
          options: [],
          loading: false,
          testid: "account-balance-sheet-rate-variance-loss",
          value: buildLabelInValue(),
        },
      ] as PreferenceFieldFormState<EnumPreferenceAccountBalanceSheetKey>[],
      options: [] as Option<AccountingAccountResponseDto>[],
    };
  },
  methods: {
    async onSearchCoa(
      field: PreferenceFieldFormState<EnumPreferenceAccountBalanceSheetKey>,
      value?: string
    ) {
      const { findAllChildAccount, filterBy, toOptions } = useCoa();
      field.isSearching = true;
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      params.search = filterBy({ code: value, description: value });
      try {
        field.loading = true;
        const response = await findAllChildAccount(params);
        field.options = toOptions(response.data);
      } finally {
        field.loading = false;
      }
    },
  },
});
