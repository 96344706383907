








































































































import { useFindMasterType } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { StorageStatePreference } from "@/store/models/Preference.model";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "PreferenceInventory",
  data() {
    return {
      booleanOptions: [
        {
          label: this.$t("lbl_yes"),
          value: "true",
        },
        {
          label: this.$t("lbl_no"),
          value: "false",
        },
      ],
      costMethodOptions: [] as Option[],
      loading: {
        costMethod: false,
      },
    };
  },
  computed: {
    ...mapState({
      state: (st: any) => st.preferenceStore as StorageStatePreference,
    }),
  },
  mounted() {
    this.initPreferenceFormInventory();
    this.fetchCostMethodList();
  },
  methods: {
    ...mapActions({
      initPreferenceFormInventory:
        "preferenceStore/initPreferenceFormInventory",
      updatePreferenceInventory: "preferenceStore/updatePreferenceInventory",
    }),
    onSave() {
      this.updatePreferenceInventory();
    },
    async fetchCostMethodList() {
      try {
        this.loading.costMethod = true;
        const res = await useFindMasterType("COSTING_METHOD");
        this.costMethodOptions = res.map(item => ({
          key: item.id,
          label: item.value,
          value: item.id,
        }));
      } finally {
        this.loading.costMethod = false;
      }
    },
  },
});
