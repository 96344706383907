var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_inventory")) + " ")]),
      _vm._l(_vm.accountState, function(field) {
        return [
          _c(
            "a-row",
            { key: field.key, attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c("a-col", { attrs: { span: 6 } }, [
                _c("p", [_vm._v(" " + _vm._s(_vm.$t(field.label)) + " ")])
              ]),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c("a-select", {
                    staticClass: "w-100",
                    attrs: {
                      "label-in-value": "",
                      "show-search": "",
                      "data-testid": field.testid,
                      loading: field.loading,
                      placeholder: _vm.$t("lbl_select_here"),
                      "dropdown-match-select-width": false,
                      "filter-option": false,
                      options: field.isSearching
                        ? field.options
                        : _vm.coaOptions
                    },
                    on: {
                      search: function($event) {
                        return _vm.onSearchCoa(field, $event)
                      }
                    },
                    model: {
                      value: _vm.state[field.name].value,
                      callback: function($$v) {
                        _vm.$set(_vm.state[field.name], "value", $$v)
                      },
                      expression: "state[field.name].value"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }