
































import { buildPreferenceFieldFormStates } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { PreferenceFieldFormState } from "@/models/interface/preference";
import {
  EnumPreferenceAccountTaxKey,
  PreferenceAccountTaxState,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapState } from "vuex";

const names: EnumPreferenceAccountTaxKey[] = ["vatIn", "vatOut"];
const testids: string[] = ["account-tax-vat-in", "account-tax-vat-out"];
const keys: EnumPreferenceKey[] = [
  "account_setup_vat_in",
  "account_setup_vat_out",
];
const labels: string[] = ["lbl_vat_in", "lbl_vat_out"];

export default Vue.extend({
  name: "PreferenceAccounTax",
  inject: ["PreferenceAccount"],
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.account.tax as PreferenceAccountTaxState,
    }),
    coaOptions(): Option<AccountingAccountResponseDto>[] {
      return this.isSearching ? this.options : this.PreferenceAccount().options;
    },
  },
  data() {
    this.onSearchCoa = debounceProcess(this.onSearchCoa, 500);
    return {
      accountState: buildPreferenceFieldFormStates({
        keys,
        labels,
        names,
        testids,
      }) as PreferenceFieldFormState<EnumPreferenceAccountTaxKey>[],
      options: [] as Option<AccountingAccountResponseDto>[],
    };
  },
  methods: {
    async onSearchCoa(
      field: PreferenceFieldFormState<EnumPreferenceAccountTaxKey>,
      value?: string
    ) {
      const { findAllChildAccount, filterBy, toOptions } = useCoa();
      field.isSearching = true;
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      params.search = filterBy({ code: value, description: value });
      try {
        field.loading = true;
        const response = await findAllChildAccount(params);
        field.options = toOptions(response.data);
      } finally {
        field.loading = false;
      }
    },
  },
});
