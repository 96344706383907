


































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import {
  useContactData,
  useCurrency,
  useFindMasterType,
  useTax,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { settingsServices } from "@/services/settings.service";
import { StorageStatePreference } from "@/store/models/Preference.model";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "PreferenceFeature",
  data() {
    this.onSearchCurrency = debounceProcess(this.onSearchCurrency, 500);
    this.onSearchSalesTax = debounceProcess(this.onSearchSalesTax, 500);
    this.onSearchPurchaseTax = debounceProcess(this.onSearchPurchaseTax, 500);
    this.onSearchEmployee = debounceProcess(this.onSearchEmployee, 500);
    return {
      booleanOptions: [
        {
          label: this.$t("lbl_yes"),
          value: "true",
        },
        {
          label: this.$t("lbl_no"),
          value: "false",
        },
      ],
      currencyOptions: [] as Option[],
      decimalFormatOptions: [] as Option[],
      decimalPointOptions: [] as Option[],
      discountOptions: [] as Option[],
      salesTaxOptions: [] as Option[],
      purchaseTaxOptions: [] as Option[],
      employeeOptions: [] as Option[],
      loading: {
        currency: false,
        decimalFormat: false,
        decimalPoint: false,
        discount: false,
        salesTax: false,
        purchaseTax: false,
        employee: false,
      },
    };
  },
  computed: {
    ...mapState({
      state: (st: any) => st.preferenceStore as StorageStatePreference,
    }),
  },
  mounted(): void {
    this.initPreferenceFormFeature();
    this.fetchCurrencyList();
    this.fetchDecimalFormatList();
    this.fetchDecimalPointList();
    this.fetchDiscountList();
    this.fetchSalesTaxRateList();
    this.fetchPurchaseTaxRateList();
    this.fetchEmployeeList();
  },
  methods: {
    ...mapActions({
      updatePreferenceFeature: "preferenceStore/updatePreferenceFeature",
      initPreferenceFormFeature: "preferenceStore/initPreferenceFormFeature",
    }),
    async fetchEmployeeList(search?: string) {
      const { findAllEmployee } = useContactData();
      const params = new RequestQueryParams();
      params.search = search ?? "";
      params.sorts = "firstName:asc";
      try {
        this.loading.employee = true;
        const response = await findAllEmployee(params);
        this.employeeOptions = response.data.map(item => ({
          key: item.id,
          label: item.employeePosition,
          value: item.id,
        }));
      } finally {
        this.loading.employee = false;
      }
    },
    async onSearchEmployee(value?: string) {
      const { filterBy } = useContactData();
      const search = filterBy({
        firstName: value ?? "",
        lastName: value ?? "",
      });
      this.fetchEmployeeList(search);
    },
    async onSearchSalesTax(value?: string) {
      this.fetchSalesTaxRateList(value);
    },
    async onSearchPurchaseTax(value?: string) {
      this.fetchPurchaseTaxRateList(value);
    },
    async fetchSalesTaxRateList(search?: string) {
      const { findAllVatOut, toOptions } = useTax();
      try {
        this.loading.salesTax = true;
        const response = await findAllVatOut(search);
        this.salesTaxOptions = toOptions(response.data);
      } finally {
        this.loading.salesTax = false;
      }
    },
    async fetchPurchaseTaxRateList(search?: string) {
      const { findAllVatIn, toOptions } = useTax();
      try {
        this.loading.purchaseTax = true;
        const response = await findAllVatIn(search);
        this.purchaseTaxOptions = toOptions(response.data);
      } finally {
        this.loading.purchaseTax = false;
      }
    },
    async fetchDiscountList() {
      try {
        this.loading.discount = true;
        const response = await useFindMasterType("DISCOUNT_CALCULATION");
        this.discountOptions = response.map(dt => ({
          key: dt.id,
          label: dt.value,
          value: dt.id,
        }));
      } finally {
        this.loading.discount = false;
      }
    },
    async fetchDecimalPointList() {
      try {
        this.loading.decimalPoint = true;
        const response = await useFindMasterType("DECIMAL_POINT");
        this.decimalPointOptions = response.map(dt => ({
          key: dt.id,
          label: dt.value,
          value: dt.value,
        }));
      } finally {
        this.loading.decimalPoint = false;
      }
    },
    async fetchDecimalFormatList() {
      try {
        this.loading.decimalFormat = true;
        const response = await useFindMasterType("DECIMAL_FORMAT");
        this.decimalFormatOptions = response.map(dt => ({
          key: dt.id,
          label: dt.value,
          value: dt.id,
        }));
      } finally {
        this.loading.decimalFormat = false;
      }
    },
    onSave() {
      this.updatePreferenceFeature();
    },
    async fetchCurrencyList(): Promise<void> {
      const { toOptionsNew } = useCurrency();
      try {
        this.loading.currency = true;
        const response = await settingsServices.getListMasterCurrency();
        this.currencyOptions = toOptionsNew(response.data);
      } finally {
        this.loading.currency = false;
      }
    },
    onSearchCurrency(value: string | null): void {
      const { toOptionsNew } = useCurrency();
      let search = "";
      if (value) {
        search = new SearchBuilder()
          .push(["currencyCode", value], { like: "both" })
          .or()
          .push(["description", value], { like: "both" })
          .build();
      }
      this.loading.currency = true;
      settingsServices
        .getListMasterCurrency({ search: search ?? undefined })
        .then(({ data }) => {
          this.currencyOptions = toOptionsNew(data);
        })
        .finally(() => {
          this.loading.currency = false;
        });
    },
  },
});
