var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 48], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [_c("PreferenceAccountBalanceSheet")],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [_c("PreferenceAccountSalesPurchase")],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [_c("PreferenceAccountArAp")],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [_c("PreferenceAccountInventory")],
            1
          ),
          _c("a-col", { attrs: { span: 24 } }, [_c("PreferenceAccountTax")], 1)
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mt-3", attrs: { gutter: [16, 48], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _vm.$can("update", "preferences")
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "account-save",
                        loading: _vm.store.loadingSave
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }