











































































































































































import { debounceProcess } from "@/helpers/debounce";
import { useBranch, useCoa, useContactData, useTax } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  PreferenceTruckState,
  StorageStatePreference,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "PreferenceTruck",
  data() {
    this.onSearchTaxVatOut = debounceProcess(this.onSearchTaxVatOut, 500);
    this.onSearchEmployee = debounceProcess(this.onSearchEmployee, 500);
    this.onSearchAccount = debounceProcess(this.onSearchAccount, 500);
    this.fetchBranchList = debounceProcess(this.fetchBranchList, 500);
    return {
      vatOutTaxOptions: [] as Option[],
      employeeOptions: [] as Option[],
      accountDownPaymentOptions: [] as Option[],
      accountRevenueOptions: [] as Option[],
      accountReceivableOptions: [] as Option[],
      branchOptions: [] as Option[],
      loading: {
        tax: false,
        assignee: false,
        accountDownPaymentOptions: false,
        accountRevenueOptions: false,
        accountReceivableOptions: false,
        branchOptions: false,
      },
    };
  },
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.truck as PreferenceTruckState,
      store: (st: any) => st.preferenceStore as StorageStatePreference,
    }),
  },
  mounted(): void {
    this.initPreferenceFormTruck();
    this.fetchEmployeeList();
    this.fetchTaxVatOutList();
    this.fetchAccountList();
    this.fetchBranchList();
  },
  methods: {
    ...mapActions({
      updatePreferenceTruck: "preferenceStore/updatePreferenceTruck",
      initPreferenceFormTruck: "preferenceStore/initPreferenceFormTruck",
    }),
    async fetchEmployeeList(search?: string) {
      const { findAllEmployee } = useContactData();
      const params = new RequestQueryParams();
      params.search = search ?? "";
      params.sorts = "firstName:asc";

      try {
        this.loading.assignee = true;
        const response = await findAllEmployee(params);
        this.employeeOptions = response.data.map(item => ({
          key: item.id,
          label: item.employeePosition,
          value: item.id,
        }));
      } finally {
        this.loading.assignee = false;
      }
    },
    async onSearchEmployee(value?: string) {
      const { filterBy } = useContactData();
      const search = filterBy({
        firstName: value ?? "",
        lastName: value ?? "",
      });
      this.fetchEmployeeList(search);
    },
    async onSearchTaxVatOut(value?: string) {
      this.fetchTaxVatOutList(value);
    },
    async fetchTaxVatOutList(search?: string) {
      const { findAllVatOut, toOptions } = useTax();
      try {
        this.loading.tax = true;
        const response = await findAllVatOut(search);
        this.vatOutTaxOptions = toOptions(response.data);
      } finally {
        this.loading.tax = false;
      }
    },
    async onSearchAccount(
      field:
        | "accountDownPaymentOptions"
        | "accountRevenueOptions"
        | "accountReceivableOptions",
      value?: string
    ) {
      const { findAllChildAccount, filterBy, toOptions } = useCoa();
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      params.search = filterBy({ code: value, description: value });
      try {
        this.loading[field] = true;
        const response = await findAllChildAccount(params);
        this[field] = toOptions(response.data);
      } finally {
        this.loading[field] = false;
      }
    },
    async fetchAccountList() {
      const { findAllChildAccount, toOptions } = useCoa();
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      try {
        this.loading.accountDownPaymentOptions = true;
        this.loading.accountRevenueOptions = true;
        this.loading.accountReceivableOptions = true;
        const response = await findAllChildAccount(params);
        this.accountDownPaymentOptions = toOptions(response.data);
        this.accountRevenueOptions = toOptions(response.data);
        this.accountReceivableOptions = toOptions(response.data);
      } finally {
        this.loading.accountDownPaymentOptions = false;
        this.loading.accountRevenueOptions = false;
        this.loading.accountReceivableOptions = false;
      }
    },
    async fetchBranchList(query?: string) {
      const { findAll, toOptions, searchBy } = useBranch();
      this.loading.branchOptions = true;

      const params = new RequestQueryParams();
      params.search = searchBy({ name: query ?? "" });
      findAll(params)
        .then(({ data }) => {
          this.branchOptions = toOptions(data);
        })
        .finally(() => {
          this.loading.branchOptions = false;
        });
    },
    onSave() {
      this.updatePreferenceTruck();
    },
  },
});
