

























import {
  PreferenceAccount,
  PreferenceFeature,
  PreferenceInventory,
  PreferencePurchase,
  PreferenceSales,
  PreferenceTruck,
} from "@/components/Preference";
import Vue from "vue";

type Tab = "feature" | "sales" | "purchase" | "inventory";

export default Vue.extend({
  name: "IndexPage",
  components: {
    PreferenceFeature,
    PreferenceSales,
    PreferencePurchase,
    PreferenceInventory,
    PreferenceAccount,
    PreferenceTruck,
  },
  data() {
    return {
      currentTab: "feature" as Tab,
    };
  },
  created() {
    this.currentTab = (this.$route.query.tab as Tab | undefined) ?? "feature";
  },
  methods: {
    onChangeTab(key: Tab) {
      this.$router.replace({
        name: "settings.preference.list",
        query: {
          tab: key,
        },
      });
    },
  },
});
