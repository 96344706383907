
































import { buildPreferenceFieldFormStates } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { PreferenceFieldFormState } from "@/models/interface/preference";
import {
  EnumPreferenceAccountSalesPurchaseKey,
  PreferenceAccountSalesPurchaseState,
} from "@/store/models/Preference.model";
import Vue from "vue";
import { mapState } from "vuex";

const names: EnumPreferenceAccountSalesPurchaseKey[] = [
  "salesRevenue",
  "salesDiscount",
  "salesReturn",
  "salesDelivery",
  "unearnedRevenue",
  "unbilledSales",
  "unbilledReceivables",
  "salesBadDebt",
  "purchaseBadDebt",
  "purchaseCogs",
  "purchaseShipping",
  "purchaseReturn",
  "unbilledPayable",
  "roundingAccountGain",
  "roundingAccountLoss",
  "purchasePriceVariance",
  "purchaseDiscount",
  "prepaymentDealer",
];

const testids: string[] = [
  "account-sales-purchase-sales-revenue",
  "account-sales-purchase-sales-discount",
  "account-sales-purchase-sales-return",
  "account-sales-purchase-sales-delivery",
  "account-sales-purchase-unearned-revenue",
  "account-sales-purchase-unbilled-sales",
  "account-sales-purchase-unbilled-receivables",
  "account-sales-purchase-sales-bad-debt",
  "account-sales-purchase-purchase-bad-debt",
  "account-sales-purchase-purchase-cogs",
  "account-sales-purchase-purchase-shipping",
  "account-sales-purchase-purchase-return",
  "account-sales-purchase-unbilled-payable",
  "account-sales-purchase-rounding-account-gain",
  "account-sales-purchase-rounding-account-loss",
  "account-sales-purchase-purchase-price-variance",
  "account-sales-purchase-purchase-discount",
  "account-sales-purchase-prepayment-dealer",
];
const keys: EnumPreferenceKey[] = [
  "account_setup_sales_revenue",
  "account_setup_sales_discount",
  "account_setup_sales_return",
  "account_setup_sales_delivery",
  "account_setup_unearned_revenue",
  "account_setup_unbilled_sales",
  "account_setup_unbilled_receivables",
  "account_setup_sales_bad_debt",
  "account_setup_purchase_bad_debt",
  "account_setup_purchase_cogs",
  "account_setup_purchase_shipping",
  "account_setup_purchase_return",
  "account_setup_unbilled_payables",
  "account_setup_rounding_account_gain",
  "account_setup_rounding_account_loss",
  "account_setup_purchase_price_variance",
  "account_setup_purchase_discount",
  "account_setup_prepayment_dealer",
];
const labels: string[] = [
  "lbl_sales_revenue",
  "lbl_sales_discount",
  "lbl_sales_return",
  "lbl_sales_delivery",
  "lbl_unearned_revenue",
  "lbl_unbilled_sales",
  "lbl_unbilled_receivables",
  "lbl_sales_bad_debt",
  "lbl_purchase_bad_debt",
  "lbl_purchase_cogs",
  "lbl_purchase_shipping",
  "lbl_purchase_return",
  "lbl_unbilled_payable",
  "lbl_rounding_account_gain",
  "lbl_rounding_account_loss",
  "lbl_purchase_price_variance",
  "lbl_purchase_discount",
  "lbl_prepayment_dealer",
];

export default Vue.extend({
  name: "PreferenceAccountSalesPurchase",
  inject: ["PreferenceAccount"],
  computed: {
    ...mapState({
      state: (st: any) =>
        st.preferenceStore.field.account
          .salesPurchase as PreferenceAccountSalesPurchaseState,
    }),
    coaOptions(): Option<AccountingAccountResponseDto>[] {
      return this.isSearching ? this.options : this.PreferenceAccount().options;
    },
  },
  data() {
    this.onSearchCoa = debounceProcess(this.onSearchCoa, 500);
    return {
      accountState: buildPreferenceFieldFormStates({
        testids,
        keys,
        labels,
        names,
      }) as PreferenceFieldFormState<EnumPreferenceAccountSalesPurchaseKey>[],
      options: [] as Option<AccountingAccountResponseDto>[],
    };
  },
  methods: {
    async onSearchCoa(
      field: PreferenceFieldFormState<EnumPreferenceAccountSalesPurchaseKey>,
      value?: string
    ) {
      const { findAllChildAccount, filterBy, toOptions } = useCoa();
      field.isSearching = true;
      const params = new RequestQueryParams();
      params.sorts = "code:asc";
      params.search = filterBy({ code: value, description: value });
      try {
        field.loading = true;
        const response = await findAllChildAccount(params);
        field.options = toOptions(response.data);
      } finally {
        field.loading = false;
      }
    },
  },
});
